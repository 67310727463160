import request from "../../utils/request";
import getHeaders from "../../utils/headers";

export function obtenerDatosFiltroAutocomplete(contexto, url, valor, selectedCountry = 'argentina') {
    let prefix;
    switch (selectedCountry) {
      case 'argentina':
        prefix = '/filtros';
        break;
      case 'mexico':
        prefix = '/filtros-mex';
        break;
      case 'brasil':
        prefix = '/filtros-bra';
        break;
      default:
        prefix = '/filtros';
    }
    return request(
      `${process.env.VUE_APP_URL_MS}${prefix}/${url}/${valor}`,
      {
        method: "GET",
        headers: getHeaders(contexto),
      },
      contexto
    );
  }
  
  export function obtenerDatosFiltroSelect(contexto, url, selectedCountry = 'argentina') {
    let prefix;
    switch (selectedCountry) {
      case 'argentina':
        prefix = '/filtros';
        break;
      case 'mexico':
        prefix = '/filtros-mex';
        break;
      case 'brasil':
        prefix = '/filtros-bra';
        break;
      default:
        prefix = '/filtros';
    }
    return request(
      `${process.env.VUE_APP_URL_MS}${prefix}/${url}`,
      {
        method: 'GET',
        headers: getHeaders(contexto),
      },
      contexto
    );
  }
  
  export function obtenerDatosFiltroAutocompleteDepends(contexto, url, data, selectedCountry = 'argentina') {
    let prefix;
    switch (selectedCountry) {
      case 'argentina':
        prefix = '/filtros';
        break;
      case 'mexico':
        prefix = '/filtros-mex';
        break;
      case 'brasil':
        prefix = '/filtros-bra';
        break;
      default:
        prefix = '/filtros';
    }
    return request(
      `${process.env.VUE_APP_URL_MS}${prefix}/${url}`,
      {
        method: 'POST',
        headers: getHeaders(contexto),
        body: JSON.stringify(data),
      },
      contexto
    );
  }


// baseURL = process.env.VUE_APP_SERVER_URL

// export function obtenerDatosFiltroAutocomplete(contexto, url, valor, selectedCountry = 'argentina') {
//     const prefix = selectedCountry === 'argentina' ? '/filtros' : '/filtros-mex';
//     return request(
//         `${process.env.VUE_APP_URL_MS}${prefix}/${url}/${valor}`,
//         {
//             method: "GET",
//             headers: getHeaders(contexto),
//         },
//         contexto
//     );
// }

// export function obtenerDatosFiltroSelect(contexto, url, selectedCountry = 'argentina') {
//     const prefix = selectedCountry === 'argentina' ? '/filtros' : '/filtros-mex';
//     return request(
//         `${process.env.VUE_APP_URL_MS}${prefix}/${url}`,
//         {
//             method: 'GET',
//             headers: getHeaders(contexto),
//         },
//         contexto
//     );
// }

// // Agrega esta función para manejar filtros que dependen de otros filtros
// export function obtenerDatosFiltroAutocompleteDepends(contexto, url, data, selectedCountry = 'argentina') {
//     const prefix = selectedCountry === 'argentina' ? '/filtros' : '/filtros-mex';
//     return request(
//         `${process.env.VUE_APP_URL_MS}${prefix}/${url}`,
//         {
//             method: 'POST',
//             headers: getHeaders(contexto),
//             body: JSON.stringify(data),
//         },
//         contexto
//     );
// }
