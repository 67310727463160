import request from "../../utils/request";
import getHeaders from "../../utils/headers";

// // Modificación para aceptar una URL base alternativa
// export default function obtenerDatosKPI(
//     contexto,
//     url,
//     filtros,
//     selectedCountry = 'argentina',
//     baseURL = process.env.VUE_APP_SERVER_URL
//   ) {
//     const prefix = selectedCountry === 'argentina' ? '/graficas' : '/graficas-mex';
  
//     return request(
//       `${baseURL}${prefix}${url}`,
//       {
//         method: 'POST',
//         headers: getHeaders(contexto),
//         body: JSON.stringify({ filtros }),
//       },
//       contexto
//     );
//   }
  

function getPrefixGraficas(country) {
  const mapping = {
    argentina: '/graficas',
    mexico: '/graficas-mex',
    brasil: '/graficas-bra',
  };
  // Si no está en el mapping, usa '/graficas' por defecto
  return mapping[country] || '/graficas';
}

export default function obtenerDatosKPI(
  contexto,
  url,
  filtros,
  selectedCountry = 'argentina',
  baseURL = process.env.VUE_APP_SERVER_URL
) {
  const prefix = getPrefixGraficas(selectedCountry);

  return request(
    `${baseURL}${prefix}${url}`,
    {
      method: 'POST',
      headers: getHeaders(contexto),
      body: JSON.stringify({ filtros }),
    },
    contexto
  );
}
