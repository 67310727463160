import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        selectedCountry: 'argentina',
        usuario: {
            rol: null,
            token: null,
            nombres: null,
            validar: false,
        },
        filtros: {
            biomarcador: [],
            sponsor: [],
            laboratorio: [],
            fecha: {
                desde: null,
                hasta: new Date().toISOString().slice(0, 10)
            },
            tipoTumoral: [],
            tipoResultado: [],
        },
        fechaMasAntigua: null
    },
    mutations: {
        setUsuario(state, usuario) {
            state.usuario = usuario;
            localStorage.setItem('token', state.usuario.token);
        },
        agregarFiltro(state, { filtro, valor }) {
            state.filtros[filtro] = valor
        },
        setFechaDesde(state, fecha) {
            state.filtros.fecha.desde = fecha
        },
        setFechaHasta(state, fecha) {
            state.filtros.fecha.hasta = fecha
        },
        setFechaMasAntigua(state, fecha) {
            state.fechaMasAntigua = fecha
        },
        clearFiltros(state) {
            state.filtros.biomarcador = [];
            state.filtros.sponsor = [];
            state.filtros.laboratorio = [];
            state.filtros.fecha.desde = state.fechaMasAntigua;
            state.filtros.fecha.hasta = new Date().toISOString().slice(0, 10);
            state.filtros.tipoTumoral = [];
            state.filtros.tipoResultado = [];
        },
        setSelectedCountry(state, country) {
            state.selectedCountry = country;
        },
    },
    actions: {
        inicializarFechaDesde({ commit, state }) {
          let prefix;
          switch (state.selectedCountry) {
            case 'argentina':
              prefix = '/filtros';
              break;
            case 'mexico':
              prefix = '/filtros-mex';
              break;
            case 'brasil':
              prefix = '/filtros-bra';
              break;
            default:
              prefix = '/filtros';
          }
          fetch(`${process.env.VUE_APP_URL_MS}${prefix}/fecha-mas-antigua`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${state.usuario.token}`,
            },
          })
            .then((r) => r.json())
            .then((resp) => {
              const fechaDesde = new Date(resp.fecha_de_ingreso).toISOString().slice(0, 10);
              commit('setFechaDesde', fechaDesde);
              commit('setFechaMasAntigua', fechaDesde);
            });
        },
        limpiarFiltros({ commit }) {
          commit('clearFiltros');
        }
      },
    // actions: {
    //     inicializarFechaDesde({ commit, state }) {
    //         const prefix = state.selectedCountry === 'argentina' ? '/filtros' : '/filtros-mex';
    //         fetch(`${process.env.VUE_APP_URL_MS}${prefix}/fecha-mas-antigua`, {
    //           method: 'GET',
    //           headers: {
    //             Authorization: `Bearer ${state.usuario.token}`,
    //           },
    //         })
    //           .then((r) => r.json())
    //           .then((resp) => {
    //             const fechaDesde = new Date(resp.fecha_de_ingreso).toISOString().slice(0, 10);
    //             commit('setFechaDesde', fechaDesde);
    //             commit('setFechaMasAntigua', fechaDesde);
    //           });
    //       },
    //     limpiarFiltros({ commit }) {
    //         commit('clearFiltros');
    //     }
    // },
    getters: {
        objetoPeticion: state => (idBiomarcador = null) => {
            return [{
                    nombreCampo: 'Biomarcador_ID',
                    tipo: idBiomarcador ? 'equals' : 'or',
                    filtros: idBiomarcador || state.filtros.biomarcador.map(r => r.id)
                },
                {
                    nombreCampo: 'Farma_ID',
                    tipo: 'or',
                    filtros: state.filtros.sponsor.map(r => r.id)
                },
                {
                    nombreCampo: 'Origen_Muestra',
                    tipo: 'or',
                    filtros: state.filtros.laboratorio.map(r => r.id)
                },
                {
                    nombreCampo: 'Tumor_Primario_ID',
                    tipo: 'or',
                    filtros: state.filtros.tipoTumoral.map(r => r.id)
                },
                {
                    nombreCampo: 'Resultado_Categoria_ID',
                    tipo: 'or',
                    filtros: state.filtros.tipoResultado.map(r => r.id)
                },
                {
                    nombreCampo: 'Fecha_de_Ingreso',
                    tipo: 'between',
                    desde: state.filtros.fecha.desde,
                    hasta: state.filtros.fecha.hasta,
                }
            ]
        }
    }
})